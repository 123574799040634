
















import { Component, Vue } from 'vue-property-decorator'
import { Getter, Mutation } from 'vuex-class'
import cache from '@/common/cache';

@Component
export default class ChoosingCenter extends Vue {
  @Getter('centerList') centerList

  async handleSelect (item) {
    cache.setLocalStorageData('access_token', item.access_token);
    const { code, data, message } = await this.$rest.login.postLogin({
      center_id: item.center.id,
      user_id: item.employee.id
    })
    if (code !== 0) return;
    cache.setLocalStorageData('auth_list', data);
    this.$store.commit('UPDATE_USERINFO', {
      user_name: item.employee.name,
      center_name: item.center.name,
      center_list: this.centerList
    });
    if (data.length === 0) {
      this.$router.push('/index');
    } else {
      this.$router.push('/carManage/enterWarehouse/index');
    }
  }
}
