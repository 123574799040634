































import { Component, Vue } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import auth from '@/common/auth';
import url from '@/common/url'
import cache from '@/common/cache';

interface Form {
  telphone: string
  code: string
}
@Component
export default class Login extends Vue {
  @Action('UPDATE_CENTER_LIST') getCenterlist
  @Getter('centerList') centerList
  isTapDone = false
  form: Form = {
    telphone: '',
    code: ''
  }
  codeDisabled: boolean = true
  btnDisabled: boolean = true
  isShowCode: boolean = true
  timeCount: number = 0
  intervalId = null
  challenge_id = null

  handleTelphone () {
    let errMsg = '';
    if (this.form.telphone === '') errMsg = '请输入手机号';
    else if (!auth.regexpMap.regexp_mobile.test(this.form.telphone)) errMsg = '手机号格式不对';
    if (errMsg) {
      this.btnDisabled = true;
      return;
    }
    this.codeDisabled = false;
  }

  handleCode () {
    let errMsg = ''
    if (this.form.code === '') errMsg = '请输入验证码';
    else if (!auth.regexpMap.regexp_code.test(this.form.code)) errMsg = '验证码只能输入四位数字';
    if (errMsg) {
      this.btnDisabled = true;
      return;
    }
    this.btnDisabled = false;
  }

  async handleGetCode(){
    if (this.codeDisabled) return
    let res: any = {};
    if (this.isShowCode) {
      res = await this.$rest.login.getVerifyCode({
        mobile_number: this.form.telphone
      });
      this.isTapDone = false;
      if (res.code !== 0) return;
      this.challenge_id = res.data.challenge_id || ''
    }

    const TIME_COUNT = 60;
    if (!this.intervalId) {
      this.timeCount = TIME_COUNT;
      this.isShowCode = false;
      this.intervalId = setInterval(() => {
        if (this.timeCount > 1 && this.timeCount <= TIME_COUNT) {
          this.timeCount = this.timeCount - 1;
        } else {
          this.isShowCode = true;
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }, 1000)
    }
  }

  async handleLogin () {
    this.isTapDone = true;
    const { code, data } = await this.getCenterlist({
      mobile_number: this.form.telphone,
      code: this.form.code,
      challenge_id: this.challenge_id || ''
    })
    if (code !== 0) {
      this.isTapDone = false;
      return;
    }
    if (data.length === 0) {
      this.isTapDone = false;
      this.$message.error('您所在拍卖中心已被停用')
      return;
    }
    if (data.length > 1) {
      this.$router.push('/choosingCenter');
    } else {
      const { center, employee } = data[0];
      cache.setLocalStorageData('access_token', data[0].access_token);
      this.isTapDone = true;
      const res = await this.$rest.login.postLogin({
        center_id: center.id,
        user_id: employee.id
      })
      this.isTapDone = false;
      if (res.code !== 0) return;
      cache.setLocalStorageData('auth_list', res.data);
      if (data.length === 0) {
        this.$router.push('/index');
      } else {
        this.$router.push('/carManage/enterWarehouse/index');
      }
    }
  }

  beforeDestroy() {
    clearInterval(this.intervalId)
  }
}
