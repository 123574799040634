







import { Component, Vue } from 'vue-property-decorator'
import HeaderBox from '@/components/HeaderBox.vue'

@Component({
  components: {
    HeaderBox
  }
})
export default class Index extends Vue {
}
